<template>
<v-dialog v-model="showingUserModal" width="800" persistent minHeight="1200">
  <v-form ref="vetForm" autocomplete="off" @submit.prevent="createVeterinary">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editFormId? 'Editar Veterinaria' : 'Agregar Veterinaria' }}
        </v-card-title>

        <v-card-text style="max-height: 500px">
          <v-row class="mt-2">
            
            <v-col cols="6" md="6" >  
              <v-text-field
                ref="name"
                v-model="vet_form.name"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                :rules="[() => !!vet_form.name || 'Campo requerido']"
                label="Nombre de la clínica"
              />
            </v-col>

            <v-col cols="6" md="6" >  
              <v-text-field
                ref="address"
                v-model="vet_form.address"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                label="Dirección"
              />
            </v-col>

            <v-col cols="6" md="6" >  
              <v-text-field
                ref="phone"
                v-model="vet_form.phone"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                label="Teléfono"
              />
            </v-col>

            <v-col cols="6" md="6" >  
              <v-text-field
                ref="email"
                v-model="vet_form.email"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                label="Email"
              />
            </v-col>

            <v-col cols="6" md="6" >  
              <v-text-field
                ref="cuit"
                v-model="vet_form.cuit"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                label="Cuit"
              />
            </v-col>

            <v-col cols="6" v-if="!hideFieldsForUserForm">
                <template>
                  <v-autocomplete
                    v-model="vet_form.userdata"
                    append-icon=""
                    :prepend-inner-icon="icons.mdiMagnify"
                    :items="userData"
                    :search-input.sync="searchUser"
                    outlined
                    chips
                    deletable-chips
                    small-chips
                    hide-details
                    label="Seleccionar veterinarios"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                  >
                  <template #item="data"> 
                      <v-list-item-content> <v-list-item-title>{{ data.item.name }}</v-list-item-title> </v-list-item-content>
                  </template>

                  <v-list-item 
                    v-show="page_size < userCount"
                    slot="append-item"
                    class="custom-item"
                  >
                    <span class="font-italic text-body-2"> Busca para más elementos <v-icon small> {{icons.mdiMagnify}} </v-icon> </span>
                  </v-list-item>

                  <template v-slot:no-data>
                    <v-list-item> <v-list-item-title> No se encontraron <strong>Veterinarios</strong> </v-list-item-title> </v-list-item>
                  </template>

                  </v-autocomplete>
                </template>
              </v-col>
          </v-row>
            
        </v-card-text>

        <v-col align="right" v-if="errorMessage">
          <span class="caption" style="color:red;">
            {{ errorMessage }}
          </span>
        </v-col>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
              CANCELAR
          </v-btn>
          <v-btn color="primary" type="submit">
            {{ editFormId? 'EDITAR VETERINARIA' : 'AGREGAR VETERINARIA' }}
          </v-btn>
        </v-card-actions>

      </v-card>

  </v-form>
</v-dialog>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import { 
  mdiMagnify,
  mdiClockTimeFourOutline,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiPlus
} from '@mdi/js'
import UserFormModal from '../Users/UserFormModal.vue'

export default {
  data: () => {
    return {
      page: 1,
      page_size: 10,
      searchUser: '',
      userCount: 0,
      vet_form: {
        userdata: [],
        name: "",
        address: "",
        phone: "",
        email: "",
        cuit: ""
      },
      userData: [],
      showingUserModal: false,
      hideFieldsForUserForm: false,
      editFormId: null,
      errorMessage: null,
      rules: {
        required: value => !!value || 'Campo requerido',
        minLength: value => value && value.length  >= 8 || 'Ingrese un mínimo de 8 carácteres',
        email: value => { 
          const pattern = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) ||  'Ingrese un mail valido';
        },
      },
    };
  },
  mounted() {
    this.getVeterinaryUsersList()
  },
  methods: {
    handleUserSearch: debounce(function () {
      this.page = 1;
      this.getVeterinaryUsersList();
    }, 400),
    async getVeterinaryUsersList() {

      try {
        const data = {
          page_size: this.page_size,
          page: this.page,
          summary: true,
          search: this.searchUser,
          role: 0, // Rol Veterinario = 0
        };
        const response = await this.$api.getUsersList(data);
        // console.log(response)
        this.userData = response.results
        this.userCount = response.count
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    openModal(id_to_edit) {
      // console.log(id_to_edit);
      id_to_edit
          ? this.setItemToEdit(id_to_edit)
          : this.showingUserModal = true;
    },
    openModalFromUsers(){ // if you open this Form from the userFormModal & not from ListVeterinaryClinic
      this.showingUserModal = true;
      this.hideFieldsForUserForm = true; 
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getVeterinary(id_to_edit);
 
      if (item) {
        this.editFormId = id_to_edit;
        this.vet_form.name = item.data.name ?? null;
        this.vet_form.address = item.data.address ?? null;
        this.vet_form.phone = item.data.phone ?? null;
        this.vet_form.email = item.data.email ?? null;
        this.vet_form.cuit = item.data.cuit ?? null;
        this.vet_form.userdata = item.data.userdata ?? null;
     
        this.showingUserModal = true;
      }
    },
    async createVeterinary() {
      if (this.$refs.vetForm.validate()) {
        const form = this.parseForm();
        let editForm = JSON.parse(JSON.stringify(form))
        // console.warn(editForm)
        editForm.userdata = editForm.userdata.map(el => el.id ? el.id : el)

        // console.log(form);
        try {
          const response = this.editFormId
            ? await this.$api.updateVeterinary(this.editFormId, editForm)
            : await this.$api.createVeterinary(form);

          this.$emit('display-alert', {type: 'success', message: this.editFormId ? 'Veterinaria editada correctamente' : 'Se ha creado una nueva veterinaria'});
          this.$emit("reload-vet-list");
          this.close();
        }
        catch (err) {
          if(err.response.data.name) {
            this.errorMessage = 'Ya existe una Veterinaria con ese nombre'
          }
        }
      }
    },
    close() {
      this.hideFieldsForUserForm = false
      this.resetForm();
      this.showingUserModal = false;
    },
    resetForm() {
      this.$refs.vetForm.reset();
      this.editFormId = null;
      this.usernameError = null;
      this.localError = null;
    },
    parseForm() {
      const formParsed = JSON.parse(JSON.stringify(this.vet_form));
        
      return formParsed;
    },

    },
    setup() {
      return {
        icons: {
          mdiMagnify,
          mdiClose,
          mdiClockTimeFourOutline,
          mdiEye,
          mdiEyeOff,
          mdiPlus
        },
      };
    },
    computed: {
      getUserRole() { return this.$store.getters["session/getUserRole"](); },
    },
    watch: {
      searchUser(val) {
        this.handleUserSearch(val);
      }
    },
    components: { UserFormModal }
}
</script>
<style scoped>
.custom-item {
  background-color: rgba(151, 76, 232, 0.1) !important;
}
</style>

