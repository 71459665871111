<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>  
          Veterinarias
        </v-card-title>
        <ListVeterinaryClinic />
      </v-card>
    </div>
  </template>
  
  <script>

import ListVeterinaryClinic from '@/components/VeterinaryClinic/ListVeterinaryClinic.vue';
import { mdiHospitalBoxOutline } from '@mdi/js'

  export default {
   components: {
    ListVeterinaryClinic
},
   setup() {
      return {
        // icons
        icons: {
          mdiHospitalBoxOutline
        },
      }
    },
  
  }
  </script>