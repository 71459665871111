import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"auto","minHeight":"1200"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 text-center grey lighten-2"},[_c(VIcon,{staticClass:"me-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHospitalBoxOutline)+" ")]),_vm._v(" "+_vm._s(_vm.vetData.name)+" ")],1),_c(VDivider),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s('DIRECCION:'))]),_vm._v(" "+_vm._s(_vm.vetData.address ? _vm.vetData.address : '-')+" ")]),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s('EMAIL:'))]),_vm._v(" "+_vm._s(_vm.vetData.email ? _vm.vetData.email : '-')+" ")]),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s('TELEFONO:'))]),_vm._v(" "+_vm._s(_vm.vetData.phone ? _vm.vetData.phone : '-')+" ")]),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s('CUIT:'))]),_vm._v(" "+_vm._s(_vm.vetData.cuit ? _vm.vetData.cuit : '-')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }