import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre de veterinaria","outlined":"","append-icon":_vm.icons.mdiMagnify,"hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.vets_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Agregar Veterinaria")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.vetListTable,"search":_vm.search,"page":_vm.current_page,"server-items-length":_vm.totalListTable,"footer-props":{
        'items-per-page-options': [10, 20, 50],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}'
      }},on:{"update:page":function($event){return _vm.changingVeterinaryPage($event)},"update:items-per-page":function($event){return _vm.changingVeterinaryItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron "),_c('strong',[_vm._v("veterinarias")])])],1)]},proxy:true},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.vets_detail_modal.openModal(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.userdata",fn:function(ref){
      var item = ref.item;
return _vm._l((item.userdata),function(user,index){return _c(VChip,{key:index,staticClass:"ms-1",attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(user.name)+" ")])})}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.vets_form_modal.openModal(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteModal(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Eliminar Veterinaria? "),_c('b',{staticClass:"ps-3"},[_vm._v(_vm._s(("'" + (_vm.editedItem.name) + "'")))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.deleteVeterinary}},[_vm._v(" Eliminar ")]),_c(VSpacer)],1)],1)],1),_c('VetsDetailModal',{ref:"vets_detail_modal"}),_c('VetsFormModal',{ref:"vets_form_modal",on:{"display-alert":_vm.displayAlert,"reload-vet-list":function($event){return _vm.getVetList()}}}),_c('AlertPopUp',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }