<template>
  <div>
    <v-dialog v-model="showModal" width="auto" minHeight="1200">
      <v-card>
        <v-card-title class="text-h5 text-center grey lighten-2">
            <v-icon class="me-2" color="primary">
            {{ icons.mdiHospitalBoxOutline }}
            </v-icon> 
           {{ vetData.name }}
        </v-card-title>
        
        <v-divider></v-divider>
        
        <v-card-text>
            <strong class="me-2">{{ 'DIRECCION:'}}</strong> {{ vetData.address ? vetData.address : '-'}}
        </v-card-text>

        <v-card-text >
            <strong class="me-2">{{ 'EMAIL:'}}</strong> {{ vetData.email ? vetData.email : '-'}}
        </v-card-text>

        <v-card-text>
            <strong class="me-2">{{ 'TELEFONO:'}}</strong> {{ vetData.phone ? vetData.phone : '-' }}
        </v-card-text>

        <v-card-text>
            <strong class="me-2">{{ 'CUIT:'}}</strong> {{ vetData.cuit ? vetData.cuit : '-'}}
        </v-card-text>

      </v-card>      
    </v-dialog>
  </div>
</template>

<script>
import {
    mdiHospitalBoxOutline
} from '@mdi/js'

export default {
    data() {
        return {
            showModal: false,
            loading: true,
            vetData: {}
        }
    },
    computed: {
        
    },
    methods: {
        async openModal(id) {

            this.loading = true
            try {
                const vet = await this.$api.getVeterinary(id)

                this.loading = false
                this.showModal = true
                this.vetData = vet.data
                // console.log(this.vetData);

            } catch (e) {
                console.log(e);
            }
        },

        close() {
            this.showModal = false;
            this.loading = true
        },
    },
    setup(){

        return{
        icons: {
            mdiHospitalBoxOutline
      },
        }
    }
}
</script>

<style lang="scss" scoped>

</style>