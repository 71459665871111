
<template>
    <div>
      <v-divider class="mt-4"></v-divider>
      <!-- actions -->
			<v-card-text class="d-flex align-center flex-wrap pb-0">
				<!-- search -->
				<v-text-field
				placeholder="Buscar por nombre de veterinaria"
				outlined
        :append-icon="icons.mdiMagnify"
				v-model="search"
        @keyup="handleSearch"
				hide-details
				dense
				class="user-search me-3 mb-4"
				>
				</v-text-field>

				<v-spacer></v-spacer>
				
				<v-spacer></v-spacer>

				<div class="d-flex align-center flex-wrap">
          <v-btn 
            color="primary"
            class="mb-4 me-3"
            @click="$refs.vets_form_modal.openModal()"
            >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Agregar Veterinaria</span>
          </v-btn>
			  </div>
			</v-card-text>
  
      <!-- Table -->
      <v-data-table
      :headers="tableColumns"
      :items="vetListTable"
      :search="search"
      :page="current_page"
      :server-items-length="totalListTable"
      @update:page="changingVeterinaryPage($event)"
      @update:items-per-page="changingVeterinaryItemsPerPage($event)"
      :footer-props="{
        'items-per-page-options': [10, 20, 50],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}'
      }"
      >
      <!-- NO data -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            No se encontraron
            <strong>veterinarias</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

      <!-- name -->
      <template #[`item.name`]="{item}">
        <v-btn 
        class="no-uppercase" 
        text 
        @click="$refs.vets_detail_modal.openModal(item.id)"
        >
          {{ item.name }}
        </v-btn>
      </template>
      <!-- <template #[`item.name`]="{item}">				
        <div class="d-flex flex-column ms-3">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name}}</span>
        </div>
      </template> -->

      <!-- veterinary users -->
      <template v-slot:item.userdata="{ item }">
        <v-chip
          v-for="(user, index) in item.userdata"
          class="ms-1"
          :key="index"
          small
          outlined
        >
          {{ user.name }}
        </v-chip>
      </template>

      <!-- action -->
      <template #[`item.actions`]="{ item }">
        <v-icon
        class="me-2"
        @click="$refs.vets_form_modal.openModal(item.id)"
        color="primary"
        >
        {{ icons.mdiPencilOutline }}
        </v-icon>
        <v-icon
        @click="deleteModal(item)"
        color="error"
        >
        {{ icons.mdiDeleteOutline }}
        </v-icon>
      </template>

      </v-data-table>

      <!-- DELETE dialog -->
				<v-dialog
				v-model="dialogDelete"
				max-width="500px"
				>
				<v-card>
					
					<v-card-title>
            Eliminar Veterinaria? <b class="ps-3">{{ `'${editedItem.name}'` }}</b> 
					</v-card-title>
					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="error"
						outlined
						@click="closeDelete"
					>
						Cancelar
					</v-btn>
					<v-btn
						color="success"
						@click="deleteVeterinary"
					>
						Eliminar
					</v-btn>
					<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
				</v-dialog>

    <VetsDetailModal 
      ref="vets_detail_modal"
    />    

    <VetsFormModal 
      ref="vets_form_modal"
      @display-alert="displayAlert"
			@reload-vet-list="getVetList()"
    />

    <AlertPopUp ref="alert_pop_up"/>

  </div>
</template>
  
<script>
import { ref } from '@vue/composition-api';
import debounce from '../../plugins/debounce.js'
import VetsDetailModal from './VetsDetailModal.vue';
import VetsFormModal from './VetsFormModal.vue';
import AlertPopUp from '../AlertPopUp.vue';

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline
} from '@mdi/js'

export default {
  data: ()  => {
	return {
    current_page: 1,
    page_size: 10,
    categoryFilter: -1,
    search: '',
    totalListTable: 0,
    vetList: [],
    dialog:false,
	  dialogDelete:false,
	  editedIndex: -1,
    editedItem: {},
    defaultItem: {},
	}
  },
  methods: {
    handleSearch: debounce(function() {
      this.current_page = 1;
      this.getVetList();
    }, 400),
    changingVeterinaryPage(e) {
      this.current_page = e
      this.getVetList()
    },
    changingVeterinaryItemsPerPage(e) {
      this.page_size = e
      this.getVetList()
    },
    async getVetList(){
      const data = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.search,
        summary: false,
      }
      try {
        const response = await this.$api.getVeterinaryList(data)
        this.vetListTable = response.results
        this.totalListTable = response.count
        // console.log(this.vetListTable)

      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
	
    },
    deleteModal(item) {
      this.editedIndex = this.vetList.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
	 
      },
    async deleteVeterinary() { 
      try {
        await this.$api.deleteVeterinary(this.editedItem.id)
        this.getVetList()
        this.$refs.alert_pop_up.displayAlert({type: 'success', message: 'Veterinaria eliminada correctamente'})
        this.close()
      } catch (e) {
        console.log(e)
      }
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    //display alerts from childs
    displayAlert(message, type){
      this.$refs.alert_pop_up.displayAlert(message, type)
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
    },
    mounted() {
      this.getVetList()
      // console.log(this.getUserRole)
  },
  computed: {
    // getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {

    const vetListTable = ref([]);
  
    const tableColumns = [
      { text: 'NOMBRE', value: 'name', align: 'left', sortable: false },
      { text: 'VETERINARIOS', value: 'userdata', align: 'left', sortable: false },
      { text: 'EDICION', value: 'actions', align: 'center', sortable: false },
    ];


    const statusColor = { Confirmed: "primary", Completed: "success", Cancelled: "error" };

    return {
        vetListTable,
        tableColumns,
        icons: {
            mdiMagnify,
            mdiDeleteOutline,
            mdiPencilOutline
        },

        statusColor,
    };
  },
  components: { VetsFormModal, VetsDetailModal, AlertPopUp }
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.no-uppercase {
        text-transform: unset !important;
	}
</style>
  